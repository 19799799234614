import React, { useState } from 'react';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { FingoDialog } from '@fingo/lib/components/dialogs';
import { Tooltip, Stack, Typography, Box } from '@mui/material';
import { useBooleanState } from '@fingo/lib/hooks';
import UploadDropZoneDialogContainer from '@fingo/lib/components/containers/UploadDropZoneDialogContainer';
import { UPLOAD_PAYROLL_CONCILIATION_FILE } from '@fingo/lib/graphql';
import { useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';

const UploadPayrollConciliationDialog = () => {
  const [files, setFiles] = useState([{ name: '' }]);
  const [error, setError] = useState(null);
  const [open, toggleOpen] = useBooleanState();
  const handleClose = () => {
    toggleOpen(false);
    setFiles([{ name: '' }]);
    setError(null);
  };
  const [uploadMutation, { loading }] = useMutation(UPLOAD_PAYROLL_CONCILIATION_FILE, {
    variables: { file: files[0] },
    onCompleted: handleClose,
  });
  return (
    <>
      <Tooltip title="Subir archivo .xlsx para recaudar documentos desde cordada">
        <CloudUploadIcon
          color="primary"
          onClick={toggleOpen}
          sx={{ cursor: 'pointer' }}
        />
      </Tooltip>
      <FingoDialog
        title="Subir archivo .xslx"
        open={open}
        handleClose={handleClose}
        dialogActionButton={(
          <LoadingButton
            size="small"
            color="primary"
            variant="contained"
            onClick={uploadMutation}
            loading={loading}
            disabled={files[0].name === ''}
          >
            Subir nómina
          </LoadingButton>
        )}
      >
        <Stack direction="column" spacing={0.8}>
          <Typography variant="h2">
            Selecciona el archivo .xlsx con los campos:
          </Typography>
          <Box sx={{ fontFamily: 'Monospace' }}>invoice_id</Box>
          <Box sx={{ fontFamily: 'Monospace' }}>payment_date (opcional. e.g.: 2023-05-01)</Box>
        </Stack>
        <UploadDropZoneDialogContainer
          files={files}
          setFiles={setFiles}
          errorMutation={error}
          setErrorMutation={setError}
        />
      </FingoDialog>
    </>
  );
};

export default UploadPayrollConciliationDialog;
