import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { FingoDialog } from '@fingo/lib/components/dialogs';
import { useBooleanState, useSnackBars } from '@fingo/lib/hooks';
import { RETRIEVE_INVOICE_LOAN_TREASURY_MESSAGE, REQUEST_INVOICE_LOAN_MONEY_TRANSFER, INVOICE_LOAN_TRANSFER_INFO } from '@fingo/lib/graphql';
import { useQuery, useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';

const AcceptInvoiceLoanTransfersDialog = ({ id }) => {
  const { addAlert } = useSnackBars();
  const [openDialog, toggleDialog] = useBooleanState();
  const { data, loading } = useQuery(
    RETRIEVE_INVOICE_LOAN_TREASURY_MESSAGE,
    { variables: { id }, skip: !openDialog },
  );
  const message = data?.retrieveInvoiceLoanTreasuryMessage?.message;
  const [requestMoneyTransfer, { requestLoading }] = useMutation(
    REQUEST_INVOICE_LOAN_MONEY_TRANSFER,
    { variables: { id },
      onCompleted: toggleDialog,
      onError: (err) => addAlert({
        id: 'money-transfer-request-error',
        severity: 'error',
        message: `Error al solicitar el giro: ${err}`,
      }),
      refetchQueries: [INVOICE_LOAN_TRANSFER_INFO],
    },
  );
  return (
    <>
      <LoadingButton
        color="primary"
        variant="contained"
        size="small"
        onClick={toggleDialog}
        loading={loading}
      >
        Solicitar Giro al Fondo
      </LoadingButton>
      <FingoDialog
        open={openDialog}
        handleClose={toggleDialog}
        maxWidth="md"
        fullWidth
        title="Solicitar Giro al Fondo"
        dialogActionButton={(
          <LoadingButton
            variant="contained"
            size="small"
            onClick={requestMoneyTransfer}
            loading={requestLoading}
          >
            Girar
          </LoadingButton>
        )}
      >
        <Box>
          <Typography variant="h3" sx={{ mt: 3, mb: 2 }}>
            Mensaje a enviar:
          </Typography>
          <Typography sx={{ whiteSpace: 'pre-line' }}>{message}</Typography>
        </Box>
      </FingoDialog>
    </>
  );
};

AcceptInvoiceLoanTransfersDialog.propTypes = {
  id: PropTypes.string.isRequired,
};

export default AcceptInvoiceLoanTransfersDialog;
