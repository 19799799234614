import { FingoDataGrid } from '@fingo/lib/components/dataGrids';
import FingoMainView from '@fingo/lib/components/layout/FingoMainView';
import { Stack, Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useInvoicePreColumns } from '@fingo/lib/constants';
import { INVOICE_TRANSFER_INFO } from '@fingo/lib/graphql';
import PropTypes from 'prop-types';
import { FACTORING_COLUMNS, FACTORING_HEADERS, FACTORING_MOBILE_HEADERS } from '../../constants/treasury';
import TransfersSummary from './TransfersSummary';

const FactoringTransferInvoices = ({ company }) => {
  const [selectedDocumentIds, setSelectedDocumentIds] = useState([]);
  const cleanDocuments = useCallback(() => {
    setSelectedDocumentIds([]);
  }, []);
  const [currency, setCurrency] = useState('');

  const isRowSelectable = useCallback(({ row }) => !row.lastOperativeRequestManager, []);
  return (
    <Stack sx={{ height: 430 }}>
      <FingoMainView
        id="factoring-transfer-invoice"
        query={INVOICE_TRANSFER_INFO}
        queryCustomVariables={{
          companyId: company.id,
          allIssuedCompany: false,
          availableForTransfer: true,
          receiverId: null,
          receiver_Rut: null,
          dateIssued_Gte: null,
          dateIssued_Lte: null,
          currency,
        }}
        slots={{
          table: FingoDataGrid,
          actions: TransfersSummary,
        }}
        slotProps={{
          table: {
            checkboxSelection: true,
            columns: useInvoicePreColumns(FACTORING_COLUMNS),
            hidefooter: true,
            initialOrderBy: '-amountWithIva',
            noRowsMessage: () => <Typography>Sin Facturas, actualizar o levantar error</Typography>,
            onSelectionModelChange: (ids) => setSelectedDocumentIds(ids),
            selectionModel: selectedDocumentIds,
            includeHeaders: FACTORING_HEADERS,
            mobileHeaders: FACTORING_MOBILE_HEADERS,
            rowsPerPageOptions: [100, 150, 200],
            isRowSelectable,
          },
          actions: {
            selectedDocumentIds,
            cleanDocuments,
            modelLabel: 'invoice',
            currency,
            setCurrency,
          },
        }}
      />
    </Stack>
  );
};

FactoringTransferInvoices.propTypes = {
  company: PropTypes.shape({
    id: PropTypes.number.isRequired,
    rut: PropTypes.string.isRequired,
    companyevaluationrestrictionsSet: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        restriction: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
        resolutionExplanation: PropTypes.string.isRequired,
      }),
    ).isRequired,
    documents: PropTypes.arrayOf(
      PropTypes.shape({
        documentType: PropTypes.string.isRequired,
        exists: PropTypes.bool.isRequired,
        lastDate: PropTypes.string,
        lastFile: PropTypes.string,
      }),
    ).isRequired,
  }).isRequired,
};

export default FactoringTransferInvoices;
