import { FingoDataGrid } from '@fingo/lib/components/dataGrids';
import { PaperHeader } from '@fingo/lib/components/headers';
import FingoMainView from '@fingo/lib/components/layout/FingoMainView';
import { Grid, Typography } from '@mui/material';
import React from 'react';
import useGetCountryFromUrl from '@fingo/lib/hooks/useGetCountryFromUrl';
import useNonOperativeMovementColumns from '../../../../columns/useNonOperativeMovementColumns';
import { NON_OPERATIVE_MOVEMENTS } from '../../graphql/queries/movement';

const Movements = () => {
  const columns = useNonOperativeMovementColumns();
  const country = useGetCountryFromUrl();
  return (
    <Grid container>
      <Grid item xs={12}>
        <FingoMainView
          id="incomes-conciliation"
          query={NON_OPERATIVE_MOVEMENTS}
          queryCustomVariables={{
            countryId: country?.id,
          }}
          slots={{
            header: PaperHeader,
            table: FingoDataGrid,
          }}
          slotProps={{
            header: {
              viewTitle: 'Historial movimientos no operativos creados',
              finder: {
                searchPlaceHolder: 'Buscar',
              },
            },
            table: {
              columns,
              noRowsMessage: () => <Typography>No hay movimientos</Typography>,
              initialOrderBy: '-createdAt',
              checkboxSelection: false,
              rowsPerPageOptions: [10, 25, 50, 100],
            },
          }}
        />
      </Grid>
    </Grid>
  );
};

export default Movements;
