import { useMutation } from '@apollo/client';
import { FingoDialog } from '@fingo/lib/components/dialogs';
import {
  INVOICE_TRANSFER_INFO,
  REJECT_MONEY_TRANSFERS,
  ORDERING_PURCHASE_ORDERS,
  CONFIRMINGS_QUERY,
} from '@fingo/lib/graphql';
import { useBooleanState, useSnackBars } from '@fingo/lib/hooks';
import { LoadingButton } from '@mui/lab';
import { Box, Button } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { TRANSFERS_MASTER_ENTITIES } from '../../../graphql/queries/transfer';

const DeclineTransfersDialog = ({ selectedDocumentIds, cleanDocuments, modelLabel }) => {
  const [openDialog, toggleDialog,, closeDialog] = useBooleanState();
  const { addAlert } = useSnackBars();

  const [rejectMoneyTransfers, rejectMoneyTransfersResult] = useMutation(REJECT_MONEY_TRANSFERS, {
    variables: {
      documentsIds: selectedDocumentIds,
      modelLabel,
    },
    onCompleted: () => {
      cleanDocuments();
      addAlert({
        severity: 'success',
        message: 'Giros rechazados exitosamente',
      });
      closeDialog();
    },
    onError: () => {
      addAlert({
        severity: 'error',
        message: 'Error al rechazar giros',
      });
    },
    refetchQueries: [
      INVOICE_TRANSFER_INFO, ORDERING_PURCHASE_ORDERS, CONFIRMINGS_QUERY, TRANSFERS_MASTER_ENTITIES,
    ],
  });

  return (
    <>
      <Button
        color="primary"
        variant="contained"
        size="small"
        disabled={!selectedDocumentIds || selectedDocumentIds.length === 0}
        disableElevation
        onClick={toggleDialog}
        id="toggle-download-portfolio"
      >
        Rechazar
      </Button>
      <FingoDialog
        open={openDialog}
        handleClose={closeDialog}
        maxWidth="xs"
        fullWidth
        title="¿Quieres rechazar estos giros?"
      >
        <Box
          justifyContent="right"
          display="flex"
        >
          <LoadingButton
            color="primary"
            variant="contained"
            size="small"
            disableElevation
            id="toggle-download-portfolio"
            onClick={rejectMoneyTransfers}
            loading={rejectMoneyTransfersResult.loading}
          >
            Rechazar giros
          </LoadingButton>
        </Box>
      </FingoDialog>
    </>
  );
};

DeclineTransfersDialog.propTypes = {
  selectedDocumentIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  cleanDocuments: PropTypes.func.isRequired,
  modelLabel: PropTypes.oneOf(['invoice', 'purchaseorder', 'confirming']).isRequired,
};

export default DeclineTransfersDialog;
