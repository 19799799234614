import RaiseOperativeRequestIconButton from '@fingo/lib/views/operativeRequest/RaiseOperativeRequestIconButton';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { CurrencyFilter } from '@fingo/lib/components/filters';
import AcceptTransfersDialog from './dialogs/AcceptTransfersDialog';
import DeclineTransfersDialog from './dialogs/DeclineTransfersDialog';
import ManualTransferDialog from './dialogs/ManualTransferDialog';
import ReintegrationDialog from './dialogs/ReintegrationDialog';
import ReplacementDialog from './dialogs/ReplacementDialog';

const TransfersSummary = (
  { selectedDocumentIds, cleanDocuments, modelLabel, data, currency, setCurrency },
) => {
  const showInvoiceActions = modelLabel === 'invoice';
  return (
    <Grid
      container
      columnSpacing={1}
      rowSpacing={1}
      sx={{
        bgcolor: 'background.light',
        borderRadius: 2,
        px: 1,
        ml: 0,
        py: 1,
        '& .MuiGrid-item:first-of-type': {
          pl: 0,
        },
      }}
      display="flex"
      alignItems="center"
      justifyContent="right"
    >
      {showInvoiceActions && (
        <>
          <Grid item>
            <RaiseOperativeRequestIconButton
              setDocumentIds={cleanDocuments}
              selectedInvoices={selectedDocumentIds}
            />
          </Grid>
          <Grid item>
            <ReplacementDialog
              selectedDocumentIds={selectedDocumentIds}
              cleanDocuments={cleanDocuments}
            />
          </Grid>
          <Grid item>
            <ReintegrationDialog
              selectedDocumentIds={selectedDocumentIds}
              cleanDocuments={cleanDocuments}
            />
          </Grid>
        </>
      )}
      <Grid item>
        <DeclineTransfersDialog
          selectedDocumentIds={selectedDocumentIds}
          cleanDocuments={cleanDocuments}
          modelLabel={modelLabel}
        />
      </Grid>
      <Grid item>
        <AcceptTransfersDialog
          selectedDocumentIds={selectedDocumentIds}
          cleanDocuments={cleanDocuments}
          modelLabel={modelLabel}
          data={data}
        />
      </Grid>
      {showInvoiceActions && (
        <Grid item>
          <ManualTransferDialog
            selectedDocumentIds={selectedDocumentIds}
            cleanDocuments={cleanDocuments}
            data={data}
          />
        </Grid>
      )}
      <Grid item>
        <CurrencyFilter currency={currency} setCurrency={setCurrency} />
      </Grid>
    </Grid>
  );
};

TransfersSummary.propTypes = {
  selectedDocumentIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  cleanDocuments: PropTypes.func.isRequired,
  modelLabel: PropTypes.oneOf(['invoice', 'purchaseorder', 'confirming']).isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  ),
  currency: PropTypes.string.isRequired,
  setCurrency: PropTypes.func.isRequired,
};

TransfersSummary.defaultProps = {
  data: [],
};

export default TransfersSummary;
